import { useRouter } from 'next/router'
import { Button, Result, Typography } from 'antd'
import styles from './index.module.scss'
import PageWrapper from '../../components/Admin/utils/PageWrapper'
import { HomeOutlined, LeftOutlined } from '@ant-design/icons'
import PageHead from '../../components/Admin/AdminHeader/PageHead'

const NotFound = () => {
    const router = useRouter()

    return (
        <PageWrapper>
            <PageHead title="Not Found" />
            <div className="d-flex w-100 vh-100 flex-center">
                <div className="d-flex flex-column gap-2 align-items-center">
                    <Typography.Title level={1} className="mb-0" style={{ fontSize: 96 }}>
                        404
                    </Typography.Title>
                    <Typography.Text>Sorry, the page you visited does not exist</Typography.Text>
                    <div className="d-flex flex-row gap-2 mt-5">
                        <Button size="large" onClick={() => router.back()} icon={<LeftOutlined />}>
                            Back
                        </Button>
                        <Button
                            size="large"
                            type="primary"
                            onClick={() => router.push('/admin').then(() => router.reload())}
                            icon={<HomeOutlined />}
                        >
                            Go Home
                        </Button>
                    </div>
                </div>
            </div>
        </PageWrapper>
    )
}

export default NotFound
